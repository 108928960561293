import React, { useContext, useEffect } from 'react'
import LocaleContext from 'context/LocaleProvider'
import { graphql } from 'gatsby'
import Builder from 'components/Builder'

const PageBuilder = props => {
  const lang = useContext(LocaleContext)

  useEffect(() => {
    lang?.setPageDetails({
      uid: props?.pageContext?.alternativeLanguageUid,
      type: props?.pageContext?.pageType,
    })
  }, [])

  return <Builder {...props} />
}

export default PageBuilder

export const pageQuery = graphql`
  query page($uid: String!, $locale: String!) {
    prismicGlobalContent(lang: { eq: $locale }) { 
      lang
      data {
        scroll_down_label {
          text
        }
        read_more {
          text
        }
      }
    }
    prismicArticleGlobalContent(lang: { eq: $locale }) {
      data {
        share {
          text
        }
        back_to {
          text
        }
        estimated_read_time {
          text
        }
        by {
          text
        }
      }
    }
    prismicPage(uid: { eq: $uid }, lang: { eq: $locale }) {
      uid
      data {
        page_type
        seo_component {
          document {
            __typename
            ... on PrismicSeo {
              id
              data {
                breadcrumb_title {
                  text
                }
                open_graph_title {
                  text
                }
                open_graph_description {
                  text
                }
                open_graph_image {
                  alt
                  localFile {
                    url
                  }
                }
                seo_title {
                  text
                }
                seo_description {
                  text
                }
              }
            }
          }
        }
        components {
          component {
            document {
              __typename
              ... on PrismicIconColumnGroup {
                id
                data {
                  background_color
                  icon_group {
                    icon_title {
                      html
                      text
                    }
                    icon {
                      localFile {
                        childImageSharp {
                          fluid {
                            ...GatsbyImageSharpFluid_noBase64
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicFullwidthProjectHighlight {
                id
                data {
                  cta_styling
                  title {
                    text
                  }
                  fullwidth_background_image {
                    alt
                    localFile {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
                  cta_link {
                    uid
                  }
                  cta_label {
                    text
                  }
                  page_type
                  body {
                    text
                  }
                }
              }
              ... on PrismicBodyContentSectionWithIcons {
                id
                data {
                  section_title {
                    html
                  }
                  section_image {
                    alt
                    localFile {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
                  section_body {
                    html
                  }
                  page_type
                  icons {
                    icon_image {
                      dimensions {
                        height
                        width
                      }
                      alt
                      localFile {
                        childImageSharp {
                          fluid {
                            ...GatsbyImageSharpFluid_noBase64
                          }
                        }
                      }
                    }
                    icon_description {
                      text
                    }
                  }
                  cta_link {
                    uid
                  }
                  cta_label {
                    text
                  }
                }
              }
              ... on PrismicPrefooter {
                id
                data {
                  add_top_padding
                  body {
                    text
                  }
                  cta {
                    cta_label {
                      text
                    }
                    cta_link {
                      url
                      uid
                    }
                    cta_style
                    page_type
                  }
                  title {
                    text
                  }
                  image {
                    localFile {
                      childImageSharp {
                        fluid {
                          src
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicFeaturedArticles {
                id
                featuredArticles: data {
                  project_excerpt_title {
                    text
                  }
                  body {
                    ... on PrismicFeaturedArticlesBodyFeaturedArticles {
                      id
                      items {
                        article {
                          document {
                            ... on PrismicArticle {
                              id
                              data {
                                title {
                                  text
                                }
                                excerpt {
                                  text
                                }
                                page_type
                                date(formatString: "MMM. DD, YYYY")
                              }
                              uid
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicGenericSection {
                id
                genericSection: data {
                  body {
                    ... on PrismicGenericSectionBodyGenericSectionGroup {
                      id
                      items {
                        content_component_link {
                          document {
                            ... on PrismicBasicContainer {
                              id
                              data {
                                heading_is_scrollable
                                body {
                                  text
                                }
                                body1 {
                                  ... on PrismicBasicContainerBody1Text {
                                    items {
                                      text_body {
                                        html
                                      }
                                      text_line_decoration
                                      text_title {
                                        text
                                      }
                                    }
                                  }
                                  ... on PrismicBasicContainerBody1List {
                                    items {
                                      list {
                                        text
                                      }
                                    }
                                    primary {
                                      list_line_decoration
                                    }
                                  }
                                  ... on PrismicBasicContainerBody1Image {
                                    items {
                                      desktop_add_spacing_right
                                      desktop_touch_the_right_edge
                                      image {
                                        localFile {
                                          childImageSharp {
                                            fluid {
                                              ...GatsbyImageSharpFluid_noBase64
                                            }
                                          }
                                        }
                                      }
                                      image_line_decoration
                                      mobile_add_spacing_around
                                      mobile_add_spacing_right
                                    }
                                    id
                                    slice_type
                                    slice_label
                                  }
                                  ... on PrismicBasicContainerBody1Cta {
                                    items {
                                      cta_label {
                                        text
                                      }
                                      cta_link {
                                        url
                                        uid
                                      }
                                      cta_styling
                                    }
                                    id
                                    slice_label
                                    slice_type
                                  }
                                  ... on PrismicBasicContainerBody1Icons {
                                    items {
                                      icon_image {
                                        localFile {
                                          childImageSharp {
                                            fluid {
                                              ...GatsbyImageSharpFluid_noBase64
                                            }
                                          }
                                        }
                                      }
                                      icon_text {
                                        text
                                      }
                                      icon_title {
                                        text
                                      }
                                    }
                                    slice_label
                                    slice_type
                                  }
                                  ... on PrismicBasicContainerBody1Logo {
                                    id
                                    items {
                                      logo {
                                        localFile {
                                          childImageSharp {
                                            fluid {
                                              ...GatsbyImageSharpFluid_noBase64
                                            }
                                          }
                                        }
                                      }
                                    }
                                    slice_label
                                    slice_type
                                  }
                                }
                                title_primary {
                                  text
                                }
                                title_secondary {
                                  text
                                }
                                style_type
                                line_decoration
                                font_weight_title_primary
                                font_weight_title_secondary
                                component_position
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicProjectsGroup {
                id
                projectBodyData: data {
                  cta_link {
                    url
                    uid
                  }
                  body {
                    ... on PrismicProjectsGroupBodyProjectGroup {
                      id
                      items {
                        projects {
                          document {
                            ... on PrismicSingleProject {
                              data {
                                background_color
                                cta_link {
                                  url
                                  uid
                                }
                                cta_text
                                cta_styling
                                project_body_text {
                                  html
                                }
                                project_title_primary {
                                  text
                                }
                                project_title_secondary {
                                  text
                                }
                                project_image {
                                  localFile {
                                    childImageSharp {
                                      fluid {
                                        ...GatsbyImageSharpFluid_noBase64
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicHeroComponent {
                id
                heroData: data {
                  disable_title
                  full_screen_height
                  title_style
                  title {
                    text
                  }
                  video {
                    url
                  }
                  video_mobile {
                    url
                  }
                  body {
                    text
                  }
                  image {
                    alt
                    localFile {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
                  background_fallback
                }
              }
              ... on PrismicPlainContentComponent {
                id
                plainContentComponentData: data {
                  background_color
                  body {
                    html
                  }
                  title {
                    html
                  }
                  secondary_title {
                    text
                  }
                  image {
                    alt
                    localFile {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicColumnsComponent {
                id
                data {
                  title {
                    html
                  }
                  columns {
                    image {
                      localFile {
                        childImageSharp {
                          fluid {
                            ...GatsbyImageSharpFluid_noBase64
                          }
                        }
                      }
                    }
                    column_title {
                      html
                    }
                    column_body {
                      text
                    }
                  }
                }
              }
              ... on PrismicJobListing {
                id
                query: data {
                  title {
                    html
                  }
                  body {
                    ... on PrismicJobListingBodyListingBlock {
                      id
                      primary {
                        title {
                          html
                        }
                      }
                      slice_type
                      items {
                        cta_link {
                          url
                          target
                        }
                        cta_text {
                          text
                        }
                        location {
                          text
                        }
                        position {
                          text
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicImagesComponent {
                id
                data {
                  images {
                    image {
                      localFile {
                        childImageSharp {
                          fluid {
                            ...GatsbyImageSharpFluid_noBase64
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicContentComponent {
                id
                data {
                  body {
                    html
                  }
                  cta_link {
                    uid
                  }
                  cta_text {
                    text
                  }
                  image {
                    localFile {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                    alt
                  }
                  overline {
                    text
                  }
                  title {
                    html
                  }
                  title_bold
                  page_type
                }
              }
              ... on PrismicGroupof2 {
                id
                data {
                  components {
                    component {
                      document {
                        ... on PrismicBodyContentSectionWithIcons {
                          id
                          data {
                            cta_label {
                              text
                            }
                            cta_link {
                              uid
                            }
                            page_type
                            icons {
                              icon_description {
                                text
                              }
                              icon_image {
                                dimensions {
                                  height
                                  width
                                }
                                localFile {
                                  childImageSharp {
                                    fluid {
                                      ...GatsbyImageSharpFluid_noBase64
                                    }
                                  }
                                }
                              }
                            }
                            section_body {
                              html
                            }
                            section_title {
                              html
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicImage {
                id
                data {
                  image {
                    localFile {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                    thumbnails {
                      mobile {
                        localFile {
                          childImageSharp {
                            fluid {
                              ...GatsbyImageSharpFluid_noBase64
                            }
                          }
                        }
                      }
                    }
                    alt
                  }
                }
              }
              ... on PrismicBlogSnippet {
                id
                data {
                  cta_text {
                    text
                  }
                  title {
                    text
                  }
                  featured_articles {
                    article {
                      document {
                        ... on PrismicArticle {
                          id
                          lang
                          uid
                          data {
                            date(formatString: "MMM. DD, YYYY")
                            page_type
                            tags {
                              text
                            }
                            title {
                              text
                            }
                            excerpt {
                              text
                            }
                            content {
                              html
                            }
                            main_image {
                              alt
                              localFile {
                                childImageSharp {
                                  fluid {
                                    ...GatsbyImageSharpFluid_noBase64
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
